<template>
	<div class="w-1 fl-s-c" style="min-width: 1200px;" v-if="info!=null">
		<p class="about-title">{{info.title_name}}</p>
		<el-divider class="row-line"></el-divider>
		<!-- <div class="rich-text w-1" v-html="formatRichText(info.textcontent)"></div> -->
		<div class="rich-text w-1" v-html="info.textcontent"></div>
		<div class="fr-c" style="margin-bottom: 5rem;">
			<span class="last pointer"
				@click="toPre">{{$t('m.Previous')}}：{{front?front.title_name:$t('m.nothing')}}</span>
			<span class="next pointer"
				@click="toNext">{{$t('m.Next')}}：{{after?after.title_name:$t('m.nothing')}}</span>
		</div>
	</div>
</template>
<script>
	import formatRichText from '../utils/util.js'
	export default {
		data() {
			return {
				name: '',
				id: 0,
				info: null,
				after: null, // 下一条信息
				front: null // 上一条信息
			}
		},
		watch: {
			$route: {
				handler(newval, oldval) {
					this.name = newval.query.name
					this.id = newval.query.id
					deep: true
				},
			}
		},
		created() {
			// console.log(this.$route)
			// this.title = this.$route.query.title
			// console.log(this.$route.query)
			console.log('4545454545454545')
			this.id = this.$route.query.id
			this.name = this.$route.query.name
			this.getInfo()
		},
		methods: {
			// 处理富文本
			formatRichText,
			// 获取详情
			getInfo() {
				this.$api.home.getDetail({
					id: this.id
				}).then(res => {
					console.log(res, '详情')
					this.info = res.data.datails
					this.after = res.data.after
					this.front = res.data.front
				})
			},
			toPre() {
				if (this.front != null) {
					this.id = this.front.id
					this.$router.push({
						path:'/salesdepartmentukbranch/ukbranddetail',
						query:{
							id:this.front.id,
							name:this.$route.query.name,
							pname:this.$route.query.pname,
							pid:this.$route.query.pid
						}
					})
					this.getInfo()
				}
			},
			toNext() {
				if (this.after != null) {
					this.id = this.after.id
					this.$router.push({
						path:'/salesdepartmentukbranch/ukbranddetail',
						query:{
							id:this.after.id,
							name:this.$route.query.name,
							pname:this.$route.query.pname,
							pid:this.$route.query.pid
						}
					})
					this.getInfo()
				}
			},
		},
	}
</script>
<style scoped="scoped">
	.about-title {
		width: 1200px;
		font-weight: bold;
		text-align: center;
		color: #222222;
		font-size: 24px;
		margin-top: 50px;
	}

	.row-line {
		margin-top: 20px;
		margin-bottom: 30px;
		background: #999999;
	}

	.rich-text {
		margin-bottom: 180px;
	}

	.last {}

	.next {
		margin-left: 200px;
	}
</style>
