<template>
	<div style="background: #FFFFFF; min-width: 1400px;">
		<div class="w-1 about fl-s-c">
			<el-breadcrumb class="w-1" separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ path: '/' }">{{$t('m.homebar')}}</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/salesdepartmentukbranch',query:{pid,pname}}">{{pname}}</el-breadcrumb-item>
				<el-breadcrumb-item>{{$route.name == 'salesdepartmentukbranch'?$t('m.list'):$t('m.text')}}</el-breadcrumb-item>
			</el-breadcrumb>
			<div class="fr-s-c w-1" style="margin-top: 30px;" v-if="$route.name == 'salesdepartmentukbranch'">
				<span class="c-title">{{pname}}</span>
				<el-divider class="row-line flex-1"></el-divider>
			</div>
			<div class="w-1 fl-s-s" style="margin-top: 1.071429rem;" v-if="$route.name == 'salesdepartmentukbranch'">
				<div class="fr-b-c w-1 zz-item pointer" v-for="(item,index) in list" :key="index" @click="toDetail(item.id,item.title_name)">
					<p>· {{item.title_name}}</p>
					<span>{{item.create_time}}</span>
				</div>
			</div>
			<router-view></router-view>
			<p v-if="total==0 && $route.path=='/salesdepartmentukbranch'" style="margin: 50px; font-size: 24px;">{{$t('m.Norelevantcontent')}}</p>
			<!-- 分页器 -->
			<div class="my-pager w-1 fr-c" v-show="total >0 && $route.path == '/salesdepartmentukbranch'">
				<span v-if="$i18n.locale=='zh'">共{{Math.ceil(total/limit)}}页</span>
				<span v-else="$i18n.locale=='zh'">Total {{Math.ceil(total/limit)}} pages</span>
				<span style="margin-left: 0.625rem;" v-if="$i18n.locale=='zh'">页次：{{currentPage}}/{{Math.ceil(total/limit)}}页</span>
				<span style="margin-left: 0.625rem;" v-else>Page: {{currentPage}} of {{Math.ceil(total/limit)}}</span>
				<el-pagination style="margin-left: 0.625rem;" @current-change="handleCurrentChange"
					:current-page="currentPage"  :page-size="limit"
					layout="prev, pager, next, jumper" :total="total" :prev-text="$t('m.previouspage')" :next-text="$t('m.nextpage')">
				</el-pagination>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				title: '',
				currentPage: 1,
				pid:0,
				pname:'',
				limit:12,
				total:0,
				list:[]
			}
		},
		watch: {
			$route: {
				handler(newval,oldval) {
					if(newval.path=='/salesdepartmentukbranch'){
						console.log(newval,oldval)
						this.pid = newval.query.pid
						this.pname = newval.query.pname
						this.getList()
					}
				},
				deep: true
			},
		},
		created() {
			// console.log(this.$route)
			this.pname = this.$route.query.pname
			this.pid = this.$route.query.pid
			this.path = this.$route.path
			this.getList()
		},
		methods: {
			getList(){
				this.$api.home.getcList({
					id:this.pid,
					limit:this.limit,
					page:this.currentPage
				}).then(res=>{
					console.log(res,'分类列表')
					this.list = res.data.list
					this.total = res.data.total
				})
			},
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.currentPage = val
				this.$nextTick(()=>{
					this.getList()
				})
			},
			// 去详情
			toDetail(id,name){
				this.$router.push({
					path:'/salesdepartmentukbranch/ukbranddetail',
					query:{
						id,name,pname:this.pname,pid:this.pid
					}
				})
			}
		}
	}
</script>
<style scoped="scoped">
	.about {
		width: 1200px;
		margin: 0 auto;
	}

	.c-title {
		font-size: 24px;
		font-weight: bold;
	}

	.row-line {
		/* margin: 0; */
		margin-left: 30px;
		background: #999999;
	}

	.zz-img {
		width: 300px;
		height: 260px;

		/*  */
	}

	.zz-item {
		
		margin: 15px 0;
	}

.zz-item P{
	font-size: 14px;
	color: #555555;
}


	.zz-item span {
		font-size: 16px;
		color: #6D6D6D;
	}
	
	.my-pager{
		font-size: 14px!important;
		color: #606060;
		margin: auto;
		margin-top: 76px;
		height: 100px;
	}
</style>
