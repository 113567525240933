<template>
  <div class="fl-s-c" style="min-width: 1400px;">
		<!-- <div class="rich-text" v-if="html" v-html="formatRichText(html)"></div> -->
		<div class="rich-text" v-if="html" v-html="html"></div>
  </div>
</template>
<script>
	import formatRichText from '../utils/util.js'
	export default{
		data(){
			return{
				html:'',
				title:'',
				info:null
			}
		},
		watch:{
			$route:{
					handler(val){
						// console.log(newval,oldval)
						this.title = val.query.title
					},
				deep: true
			},
		},
		created() {
			// console.log(this.$route)
			this.getAbout()
		},
		methods:{
			formatRichText,
			getAbout(){
				this.$api.home.getAbout({}).then(res=>{
					console.log(res,'6666666666666666')
					this.html = res.data.config[13].value
				})
			}
		}
	}
</script>
<style scoped="scoped">
.about{
		padding: 0 360px;
		padding-bottom: 50px;
	}
	/* 	
	.about-title{
		font-weight: bold;
		color: #222222;
		font-size: 48px;
		margin-top: 50px;
	}
	.row-line{
		margin-top: 60px;
		margin-bottom: 50px;
		background: #999999;
	}
	.rich-text{
		margin-bottom: 180px;
	} */
	.c-title{
		font-size: 36px;
		font-weight: bold;
		color: #333333;
	}
	.contnet{
		font-size: 18px;
		color: #333333;
		margin-top: 50px;
	}
	.rich-text {
		width: 1200px;
		margin: auto;
		margin-bottom: 180px;
	}
</style>