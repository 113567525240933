<template>
  <div style="min-width: 1400px;">
		<div class="about fl-s-c">
			<p class="about-title">{{pname}}</p>
			<el-divider class="row-line"></el-divider>
			<!-- <div class="rich-text w-1" v-if="html" v-html="formatRichText(html)"></div> -->
			<div class="rich-text w-1" v-if="html" v-html="html"></div>
			
		</div>
  </div>
</template>
<script>
	import formatRichText from '../utils/util.js'
	export default{
		data(){
			return{
				html:'',
				pname:'',
				pid:0,
			}
		},
		
		watch:{
			$route: {
				handler(newval,oldval) {
					if(newval.path=='/about'){
						console.log(newval,oldval)
						this.pid = this.$route.query.pid
						this.pname = newval.query.pname
						this.getInfo()
					}
				},
				deep: true
			},
		},
		created() {
			// console.log(this.$route)
			console.log(this.$route.query,'canshu')
			this.pname = this.$route.query.pname
			this.pid = this.$route.query.pid
			this.getInfo()
		},
		methods:{
			// 处理富文本
			formatRichText,
			// 获取详情
			getInfo() {
				this.$api.home.getDetail({
					id: this.pid
				}).then(res => {
					console.log(res, '详情')
					this.html = res.data.datails.textcontent
				})
			},
			toPre() {
				if (this.front != null) {
					this.id = this.front.id
					this.getInfo()
				}
			},
			toNext() {
				if (this.after != null) {
					this.id = this.after.id
					this.getInfo()
				}
			},
		}
	}
</script>
<style scoped="scoped">
	.about{
		width: 1200px;
		margin: auto;
	}
	.about-title{
		font-weight: bold;
		color: #222222;
		font-size: 36px;
		margin-top: 50px;
	}
	.row-line{
		margin-top: 60px;
		margin-bottom: 50px;
		background: #999999;
	}
	.rich-text{
		margin-bottom: 180px;
	}
</style>