<template>
  <div style="min-width: 1400px;">
		<div class="about">
			<el-breadcrumb class="w-1" separator-class="el-icon-arrow-right">
			  <el-breadcrumb-item :to="{ path: '/' }">{{$t('m.homebar')}}</el-breadcrumb-item>
			  <el-breadcrumb-item :to="{ path: '/about',query:{pname,pid}}">{{pname}}</el-breadcrumb-item>
			  <el-breadcrumb-item>{{$t('m.text')}}</el-breadcrumb-item>
			</el-breadcrumb>
			<p class="about-title">{{pname}}</p>
			<el-divider class="row-line"></el-divider>
			<!-- <div class="rich-text w-1" v-html="formatRichText(html)"></div>			 -->
			<div class="rich-text w-1" v-html="html"></div>
		</div>
  </div>
</template>
<script>
	import formatRichText from '../utils/util.js'
	export default{
		data(){
			return{
				html:'',
				title:'',
				pname:'',
				pid:0,
				id:0
			}
		},
		
		watch:{
			$route: {
				handler(newval,oldval) {
					if(newval.path=='/about'){
						console.log(newval,oldval)
						this.pid = newval.query.pid
						this.pname = newval.query.pname
						this.getInfo()
					}
				},
				deep: true
			},
		},
		created() {
			console.log(this.$route.query,'canshu')
			this.pname = this.$route.query.pname
			this.pid = this.$route.query.pid
			this.getInfo()
		},
		methods:{
			// 处理富文本
			formatRichText,
			// 获取详情
			getInfo() {
				this.$api.home.getPageDetail({
					id: this.pid
				}).then(res => {
					console.log(res, '详情')
					this.html = res.data.textcontent
				})
			},
			toPre() {
				if (this.front != null) {
					this.id = this.front.id
					this.getInfo()
				}
			},
			toNext() {
				if (this.after != null) {
					this.id = this.after.id
					this.getInfo()
				}
			},
		}
	}
</script>
<style scoped="scoped">
	.about{
		width: 1200px;
		margin: 0 auto;
	}
	.about-title{
		text-align: center;
		font-weight: bold;
		color: #222222;
		font-size: 36px;
		margin-top: 50px;
	}
	.row-line{
		margin-top: 30px;
		margin-bottom: 20px;
		background: #999999;
	}
	.rich-text{
		margin-bottom: 180px;
	}
</style>